import Api from '@/services/Api'

export default {
	getAllNotas(){
		return Api().get('/notas/getAllNotas')
	},
	getNotaById(id){
		return Api().get('/notas/getNotaById/'+id)
	},
	createNota(formu){
		return Api().post('/notas/createNota',formu)
	},
	updateNota(info){
		return Api().put('/notas/updateNota',info)
	},
	deleteNota(id,numero){
		return Api().get('/notas/deleteNota/'+id+'/'+numero)
	},
	createNotaDetalle(formData){
		return Api().post('/notasDetalle/create',formData)
	},
	deleteNotaDetalle(id){
		return Api().delete('/notasDetalle/delete/'+id)
	},
	getNotaDetalle(vigencia,numero) {
		return Api().get('getNotaDetalle/'+vigencia+'/'+numero)
	},
}
