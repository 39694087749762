<template>
    <div class="container">
        <md-dialog :md-active.sync="showDialogEdit" :md-click-outside-to-close="false">
            <md-dialog-title class="text-center">
                {{ labels.editCompany }} <!--{{ Item }}-->
            </md-dialog-title>
            <md-dialog-content>
            <b-row class = "">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.name}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input
                                type="text"
                                name="name"
                                v-model="Item.nombre"
                                :placeholder="labels.name"                                
                            />
                        </md-field>
                    </b-col>
            </b-row>
            <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.moneda}}</h5>
                    </b-col>
                    <b-col cols="6" lg="6" class="text-left">
                        <select v-model="Item.id_moneda" id="idCurrency" class="form-control">
                            <option v-for="currency in listCurrency" :key="currency.id" :value="currency.id"> {{ currency.nombre }} </option>
                        </select>
                    </b-col>
            </b-row>
            <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.acronym}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input
                                type="text"
                                name="name"
                                v-model="Item.acronym"
                                :placeholder="labels.acronym"                                
                            />
                        </md-field>
                    </b-col>
            </b-row>
            <b-row class="">
                    <b-col cols="2"></b-col>
                    <b-col cols="4" class="text-left">
                        <h5>{{labels.code}}</h5>
                    </b-col>
                    <b-col cols="6" class="text-left">
                        <md-field>
                            <md-input
                                type="text"
                                name="name"
                                v-model="Item.codigo"
                                :placeholder="labels.codigo"                                
                            />
                        </md-field>
                    </b-col>
            </b-row>

            <md-dialog-actions class="mt-3">
                <md-button class="md-primary" @click="editCompany">{{ labels.save }}</md-button>
                <md-button class="md-accent" @click="clicked">{{ labels.close }}</md-button>
            </md-dialog-actions>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>

    import Company from "@/services/Company"
    import Currency from "@/services/Currency"

    export default {
        name : "Edit",
        props: ['showDialogEdit','Item'],

        data: () => ({
            form : {
                name : "",
                id_moneda : "",
                acronym : "",
                code : ""
            },
            listCurrency : "",
            idCurrency : ""
        }),

        computed: {
            labels(){
                if (this.$store.state._language == "es-ES") {
                    return {
                        editCompany : "Editar compañia",
                        name : "Nombre",
			moneda : "Moneda",
                        acronym : "Acrónimo",
                        code : "Código",
                        save : "Guardar",
                        close : "Cerrar"
                    }
                }else{
                    return {
                        editCompany : "Edit company",
                        name : "Name",
			moneda : "Currency",
                        acronym : "Acronym",
                        code : "Code",
                        save : "Save",
                        close : "Close"
                    }
                }
            }
        },
        methods : {
            editCompany(){
                Company.updateCompany(this.Item)
                .then((res) => {
                    // console.log(res)
                    this.$toast.success(res.data.message)
                    location.reload()
                }).catch((error) => {
                    console.log(error)
                })
            },
            clicked(){
                this.$emit('clicked', false);
            }

        },
        mounted () {
            Currency.getAllCurrency()
            .then((res) => {
                this.listCurrency = res.data.currency;
            }).catch((error) => {
                console.log(error)
            })
        }
    }
</script>
