<template>
    <div class="container">
        <div class="col-md-12">
            <h2> {{ labels.title }} </h2>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-md-8 h5">
                <label for="selVigencia" class=""> {{ labels.validity }}</label>
                <select id="selVigencia" name="" v-model="selVigencia" @click="showDialog=false;showNotaDetalle=false;showParrafo=false">
                    <option value="">{{ labels.select }}</option>
                    <option v-for="vigencia in listVigencia" :key="vigencia.vigencia"> {{ vigencia.vigencia }}</option>
                </select>&nbsp;&nbsp;
                <label  v-if="showSelCompany" for="selCompany" class=""> {{ labels.company }}</label>
                <select v-if="showSelCompany" id="selCompany" name="" v-model="selCompany" @click="showDialog=false;showNotaDetalle=false;showParrafo=false">
                    <option value="">{{ labels.select }}</option>
                    <option v-for="company in listCompany" :value="company.id" :key="company.id"> {{ company.nombre }}</option>
                </select>&nbsp;&nbsp;
                <md-button v-if="selCompany && selVigencia" style="background-color: #2e507a; width: 30px; height: 30px;" class="md-fab md-primary" @click="f_nueva_nota">
                        <md-icon>add</md-icon>
                </md-button>
            </div>
        </div>
        <div class="row col-md-12 mt-2" v-if="selVigencia && selCompany">
            <div class="row col-md-12 justify-content-center">
                <md-table md-card md-fixed-header v-model="listaNotas">
                    <md-table-row slot="md-table-row" slot-scope="{ item }" @click="showDialog=false;showNotaDetalle=false;showParrafo=false">
                        <md-table-cell :md-label="labels.numero" class="border">{{ item.numero }}</md-table-cell>
                        <md-table-cell :md-label="labels.nota" class="text-left border">{{ item.descripcion }}</md-table-cell>
                        <md-table-cell :md-label="labels.definicion" class="border">
                            <md-button style="background-color: #2e507a; width: 30px; height: 30px;" class="md-fab md-primary" @click="showNota(item.id,item.numero,item.descripcion)">
                                <md-icon>list</md-icon>
                            </md-button>
                        </md-table-cell>
                        <md-table-cell :md-label="labels.edit" class="border">
                            <md-button style="background-color: #e5ac00; width: 30px; height: 30px;" class="md-fab md-primary" @click="editNota(item.id)">
                                <md-icon>edit</md-icon>
                            </md-button>
                        </md-table-cell>
                        <md-table-cell :md-label="labels.delete" class="border">
                            <md-button style="background-color: #cd2838; width: 30px; height: 30px;" class="md-fab md-primary" @click="deleteNota(item.id,item.numero)">
                                <md-icon>delete</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
            </div>
        </div>
	<b-container v-if="showNotaDetalle">
		<md-dialog-content>
		<md-table v-model="a_detalle" md-card md-fixed-header @md-selected="f_actualiza_nota">
			<md-table-toolbar>
				<h1 class="md-title"><b><i>{{f_titulo()}}</i></b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
				</h1>
				<md-button style="background-color: #2e507a; width: 30px; height: 30px;" class="md-fab md-primary" @click="f_nuevo_parrafo">
					<md-icon>add</md-icon>
				</md-button>
			</md-table-toolbar>
			<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single">
					<md-table-cell :md-label="labels.parrafo" class="text-left border">{{item.parrafo}}</md-table-cell>
					<md-table-cell :md-label="labels.tipo" class="text-left border">{{item.tipo}}</md-table-cell>
					<md-table-cell :md-label="labels.titulo" class="text-left border" >{{item.titulo }}</md-table-cell>
					<md-table-cell :md-label="labels.orden" class="text-left border" >{{ item.orden }}</md-table-cell>
			</md-table-row>
		</md-table>
		<md-dialog-actions>
			<md-button class="md-accent" @click="showNotaDetalle = false">{{ labels.close }}</md-button>
		</md-dialog-actions>
		</md-dialog-content>
	</b-container>
	<b-container v-if="showDialog || showParrafo" class="pb-5">
		<b-row><b-col cols="6" class="text-left border"><h4><b><i>{{labels.parrafo}}</i></b></h4></b-col>
		<b-col v-if="showDialog" cols="6" class="text-left border"><h4><b><i>{{formParrafo.parrafo}}</i></b></h4></b-col>
		<b-col v-if="showParrafo" cols="6" class="border">
			<md-field> <h4><md-input v-model="formParrafo.parrafo" class="text-left border" size="60" ></md-input></h4> </md-field>
		</b-col>
		</b-row>

		<b-row><b-col cols="6" class="text-left border"><h4><b><i>{{labels.tipo}}</i></b></h4></b-col>
		<b-col cols="6" class="text-left border"><h4><b><i>
		<select v-model="formParrafo.tipo" @change="f_actualiza_forma">
		<option value="TFG">Texto Fijo General</option>
		<option value="TFC">Texto Fijo Compañia</option>
		<option value="TVG">Texto Variable General</option>
		<option value="TVC">Texto Variable Compañia</option>
		<option value="TBA">Tabla Contable Saldo Actual</option>
		<option value="TBT">Tabla Contable Saldo Anterior</option>
		<option value="TBC">Tabla Contable Comparativa</option>
		<option value="TBM">Tabla Manual</option>
		<option value="TNA">Tabla Niif Notas Saldo Actual</option>
		<option value="TNT">Tabla Niif Notas Saldo Anterior</option>
		<option value="TNC">Tabla Niif Notas Comparativa</option>
		</select>
		</i></b></h4></b-col>
		</b-row>

		<b-row><b-col cols="6" class="text-left border"><h4><b><i>{{labels.parrafo_titulo}}</i></b></h4></b-col>
		<b-col cols="6" class="border">
			<md-field> <h4><md-input v-model="formParrafo.titulo" class="text-left border" size="60" ></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.texto" cols="6" class="text-left border"><h4><b><i>{{labels.parrafo_texto}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.texto" cols="6" class="border" >
			<md-field> <h4><md-textarea v-model="formParrafo.texto" cols="60" rows="8" @change="f_cambia_texto"></md-textarea></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo0}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo0" type="text" size="60" @change="f_cambia_campo(0)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo1}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo1" type="text" size="60" @change="f_cambia_campo(1)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo2}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo2" type="text" size="60" @change="f_cambia_campo(2)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo3}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo3" type="text" size="60" @change="f_cambia_campo(3)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo4}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo4" type="text" size="60" @change="f_cambia_campo(4)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo5}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo5" type="text" size="60" @change="f_cambia_campo(5)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo6}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo6" type="text" size="60" @change="f_cambia_campo(6)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo7}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo7" type="text" size="60" @change="f_cambia_campo(7)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo8}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo8" type="text" size="60" @change="f_cambia_campo(8)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="text-left border"><h4><b><i> {{formParrafo.campo9}}</i></b></h4></b-col>
		<b-col v-if="formParrafo.tipo=='TVG' || formParrafo.tipo=='TVC'" cols="6" class="border" >
			<md-field> <h4><md-input v-model="formParrafo.valor_campo9" type="text" size="60" @change="f_cambia_campo(9)"></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<span v-for="(celda,idx) in formParrafo.TBLM" :key="idx">
			<b-row><b-col cols="6" class="text-left border"><h4><b><i>{{celda.descripcion}}</i></b></h4></b-col>
			<b-col cols="4" class="border" >
			<md-field> <h4><md-input v-model="celda.valor" type="text"></md-input></h4> </md-field>
			</b-col></b-row>
		</span>

		<md-button v-if="formParrafo.texto || formParrafo.TBLM" class="md-accent" @click="grabeAjusteTopside()"><h4><b>Eliminar</b></h4></md-button>
		<md-button v-if="formParrafo.texto || formParrafo.TBLM" class="md-primary" @click="grabeAjusteTopside()"><h4><b>Guardar</b></h4></md-button>
		<md-button class="md-secundary" @click="showDialog=false;showNotaDetalle=true;showParrafo=false"><h4><b>Cerrar</b></h4></md-button>
	</b-container>
	<b-container v-if="showNuevo || showEdita" class="pb-5">

		<b-row><b-col cols="6" class="text-left border"><h4><b><i>{{labels.numero}}</i></b></h4></b-col>
		<b-col v-if="showEdita" cols="6" class="text-left border"><h4><b><i>{{formNueva.numero}}</i></b></h4></b-col>
		<b-col v-if="showNuevo" cols="6" class="border">
			<md-field> <h4><md-input v-model="formNueva.numero" class="text-left border" size="60" ></md-input></h4> </md-field>
		</b-col>
		</b-row>
		<b-row><b-col cols="6" class="text-left border"><h4><b><i>{{labels.nota}}</i></b></h4></b-col>
		<b-col cols="6" class="border" >
			<md-field> <h4><md-input v-model="formNueva.descripcion" type="text" size="60" ></md-input></h4> </md-field>
		</b-col>
		</b-row>

		<md-button v-if="showNuevo" class="md-primary" @click="grabeNota()"><h4><b>Crear</b></h4></md-button>
		<md-button v-if="showEdita" class="md-primary" @click="actualizaNota()"><h4><b>Modifica</b></h4></md-button>
		<md-button class="md-accent" @click="showNuevo=false;showEdita=false"><h4><b>Cerrar</b></h4></md-button>
	</b-container>
     </div>
</template>

<script>
import Notas from '../../../services/Notas';
import PlanCuentas from '../../../services/PlanCuentas';
import Company from '../../../services/Company'
import Balance from '../../../services/BalanceNiif'

export default {
    name: 'Notas',
    data:() => ({
        isLoading : false,
        selVigencia : '',
        listVigencia : [],
        showTblNotas : false,
        listaNotas : [],
        modCreaNota : false,
        modEditaNota : false,
        infoNota : [],
        infoVigComp : {},
        deletFila : false,
        rowId : null,
        showSelCompany : false,
        selCompany : '',
        listCompany : [],
        showTblDetNotas : false,
        titleNota : '',
        notaDetalle : [],
        listCuentaByNota : [],
        modNotaCuenta : false,
        itemDetNot : null,
        numNota : "",
        numNotaDet : "",
	fgl_detalle : false,
	numero : false,
	showNotaDetalle: false,
	showDialog: false,
	showParrafo: false,

	nota: "",
	a_detalle : [],
	a_detalle_columnas : [],
	a_detalle_cuentas : [],
	a_detalle_niif : [],
	a_detalle_filas : [],
	a_detalle_tabla : [],
	a_detalle_texto : [],
	a_informacion_tblm : [],
	a_informacion_txfc : [],
	a_informacion_txfg : [],
	a_informacion_txvc : [],
	a_informacion_txvg : [],

	formNueva: {
		numero : '',
		descripcion : '',
	},
	showNuevo: false,
	showEdita: false,
}),
computed:{
	labels(){
		if	(this.$store.state._language == "es-ES"){
			return {
				title : 'Notas',
				validity : 'Vigencia',
				select : 'Seleccione...',
				numero : 'Número',
				nota : 'Nota',
				descripcion : 'Descripcion',
				definicion : 'Definicion',
				cuenta : 'Cuenta',
				description : 'Descripción',
				edit : 'Editar',
				delete : 'Eliminar',
				company : 'Compañia',
				parrafo : "Parrafo",
				parrafo_titulo : "Titulo",
				parrafo_texto : "Texto",
				tipo : "Tipo",
				titulo : "Titulo",
				orden : "Orden",
				close : "Cerrar",
			}
		}else{
			return {
				title : 'Notes',
				validity : 'Validity',
				select : 'Select...',
				numero : 'Number',
				nota : 'Note',
				descripcion : 'Description',
				definicion : 'Definition',
				cuenta : 'Account',
				description : 'Description',
				edit : 'Edit',
				delete : 'Delete',
				company : 'Company',
				parrafo : "Paragraph",
				parrafo_titulo : "Title",
				parrafo_texto : "Text",
				tipo : "Class",
				titulo : "Title",
				orden : "Order",
				close : "Close",

			}
		}
	}
},
methods:{
	actualizaTbl(){
		Notas.getAllNotas()
		.then((res) => {
			this.listaNotas = res.data.listNotas
			this.modCreaNota = false
			this.modEditaNota = false
		})
		.catch((err) => {
			console.log(err)
		})
		.finally(() => {
			this.isLoading = false;
		})
	},
	async editNota(id){
		const ed = this.listaNotas.find((nota) => nota.id = id)
		this.formNueva.id = id
		this.formNueva.numero = ed.numero
		this.formNueva.descripcion = ed.descripcion
		this.showEdita=true
	},
	deleteNota(id,numero){

		Notas.deleteNota(id,numero)
		.then((res) => {
			this.$toast.success(res.data.message)
			this.actualizaTbl()
		})
		.catch(() => console.log("Error al borrar Nota"))
	},
        async showNota(id,numero,nota){

		this.nota = nota
		this.numero = numero
		this.showNotaDetalle = false
		Balance.getNotas(this.idCompania,this.selVigencia,numero)
		.then((result) => {
			this.a_detalle = result.data.a_detalle
			this.a_detalle_columnas = result.data.a_detalle_columnas
			this.a_detalle_cuentas = result.data.a_detalle_cuentas
			this.a_detalle_niif = result.data.a_detalle_niif
			this.a_detalle_filas = result.data.a_detalle_filas
			this.a_detalle_tabla = result.data.a_detalle_tabla
			this.a_detalle_texto = result.data.a_detalle_texto
			this.a_informacion_tblm = result.data.a_informacion_tblm
			this.a_informacion_txfc = result.data.a_informacion_txfc
			this.a_informacion_txfg = result.data.a_informacion_txfg
			this.a_informacion_txvc = result.data.a_informacion_txvc
			this.a_informacion_txvg = result.data.a_informacion_txvg
		})
		.catch(() => {
			this.error = true
		});
		setTimeout(() => {
			this.Modifica = true
			this.showNotaDetalle = true
		},1000)

        },
	f_titulo() {
		let titulo = ""
		if	(this.$store.state._language == "es-ES") {
			titulo = "NOTAS ESTADOS FINANCIEROS"
		} else {
			titulo = "FINANCIAL STATEMENT NOTES"
		}
		titulo = titulo+" "+this.nota
		return titulo
		
	},
	f_nueva_nota() {
		this.formNueva.numero = ''
		this.formNueva.descripcion = ''
                this.showNuevo=true
	},
	f_nuevo_parrafo() {
		this.formParrafo = {
			parrafo : '',
			tipo : '',
			titulo : '',
			orden : '',
		}
		this.showParrafo=true
		this.showDialog=false
		this.showNotaDetalle= false
		this.Informacion = false
		this.formAnterior = this.formParrafo
	},
	f_actualiza_nota(r_parrafo) {
		this.showDialog = true
		this.showNotaDetalle= false
		this.Informacion = false
		this.formParrafo = r_parrafo
		if	(this.formParrafo.tipo == "TFG") {
			const txfg = this.a_informacion_txfg.find((parrafo) =>
				parrafo.parrafo == r_parrafo.parrafo)
			if	(txfg) {
				this.formParrafo.texto = txfg.texto
			}
		}
		if	(this.formParrafo.tipo == "TFC") {
			const txfc = this.a_informacion_txfc.find((parrafo) =>
				parrafo.parrafo == r_parrafo.parrafo)
			if	(txfc) {
				this.formParrafo.texto = txfc.texto
			}
		}
		if	(this.formParrafo.tipo == "TVG") {
			const txvg = this.a_informacion_txvg.find((parrafo) =>
				parrafo.parrafo == r_parrafo.parrafo)
			if	(txvg) {
				let txvgd = this.a_detalle_texto.find((txvg) => 
					txvg.parrafo == r_parrafo.parrafo
				)
				const dt = this.a_detalle_texto.find((variable) =>
						variable.parrafo == r_parrafo.parrafo)
				this.formParrafo.texto = txvgd.texto
				this.formParrafo.valor_campo0 = dt.campo0
				this.formParrafo.valor_campo1 = dt.campo1
				this.formParrafo.valor_campo2 = dt.campo2
				this.formParrafo.valor_campo3 = dt.campo3
				this.formParrafo.valor_campo4 = dt.campo4
				this.formParrafo.valor_campo5 = dt.campo5
				this.formParrafo.valor_campo6 = dt.campo6
				this.formParrafo.valor_campo7 = dt.campo7
				this.formParrafo.valor_campo8 = dt.campo8
				this.formParrafo.valor_campo9 = dt.campo9
				this.formParrafo.campo0 = "Campo 0"
				this.formParrafo.campo1 = "Campo 1"
				this.formParrafo.campo2 = "Campo 2"
				this.formParrafo.campo3 = "Campo 3"
				this.formParrafo.campo4 = "Campo 4"
				this.formParrafo.campo5 = "Campo 5"
				this.formParrafo.campo6 = "Campo 6"
				this.formParrafo.campo7 = "Campo 7"
				this.formParrafo.campo8 = "Campo 8"
				this.formParrafo.campo9 = "Campo 9"
			}
		}
		if	(this.formParrafo.tipo == "TVC") {
			const txvc = this.a_informacion_txvc.find((parrafo) =>
				parrafo.parrafo == r_parrafo.parrafo)
			if	(txvc) {
				let txvgd = this.a_detalle_texto.find((txvg) => 
					txvg.parrafo == r_parrafo.parrafo
				)
				const dt = this.a_detalle_texto.find((variable) =>
						variable.parrafo == r_parrafo.parrafo)
				this.formParrafo.texto = txvgd.texto
				this.formParrafo.valor_campo0 = dt.campo0
				this.formParrafo.valor_campo1 = dt.campo1
				this.formParrafo.valor_campo2 = dt.campo2
				this.formParrafo.valor_campo3 = dt.campo3
				this.formParrafo.valor_campo4 = dt.campo4
				this.formParrafo.valor_campo5 = dt.campo5
				this.formParrafo.valor_campo6 = dt.campo6
				this.formParrafo.valor_campo7 = dt.campo7
				this.formParrafo.valor_campo8 = dt.campo8
				this.formParrafo.valor_campo9 = dt.campo9
				this.formParrafo.campo0 = "Campo 0"
				this.formParrafo.campo1 = "Campo 1"
				this.formParrafo.campo2 = "Campo 2"
				this.formParrafo.campo3 = "Campo 3"
				this.formParrafo.campo4 = "Campo 4"
				this.formParrafo.campo5 = "Campo 5"
				this.formParrafo.campo6 = "Campo 6"
				this.formParrafo.campo7 = "Campo 7"
				this.formParrafo.campo8 = "Campo 8"
				this.formParrafo.campo9 = "Campo 9"
			}
		}
		if	(this.formParrafo.tipo == "TBM") {
			var av_filas = []
			var v_celda = ""
			var vd_celda = ""
			this.a_detalle_filas.forEach((fila) => {
				this.a_detalle_columnas.forEach((columna) => {
					vd_celda=fila.descripcion+'/'+columna.descripcion
					v_celda = this.a_informacion_tblm.find((celda) => 
						celda.fila == fila.fila && celda.columna == columna.columna)
					if	(v_celda) {
						av_filas.push({fila: fila.fila,columna: columna.columna,descripcion: vd_celda,valor: v_celda.valor})
					} else	{
						av_filas.push({fila: fila.fila,columna: columna.columna,descripcion: vd_celda,valor: 0})
					}
				})
			})
			this.formParrafo.TBLM = av_filas
		}
		this.formAnterior = this.formParrafo
	},
	f_actualiza_forma() {
		if	(this.formAnterior.tipo == "TFG") {
			const txfg = this.a_informacion_txfg.filter((parrafo) =>
				parrafo.parrafo != this.formAnterior.parrafo)
			if	(txfg) {
				this.a_informacion_txfg = txfg
			} else {
				this.a_informacion_txfg = []
			}
		}
		if	(this.formAnterior.tipo == "TFC") {
			const txfc = this.a_informacion_txfc.filter((parrafo) =>
				parrafo.parrafo != this.formAnterior.parrafo)
			if	(txfc) {
				this.a_informacion_txfc = txfc
			} else	{
				this.a_informacion_txfc = []
			}
		}
		if	(this.formAnterior.tipo == "TVG") {
			const txvg = this.a_informacion_txvg.filter((parrafo) =>
				parrafo.parrafo != this.formParrafo.parrafo)
			if	(txvg) {
				this.a_informacion_txvg=txvg
			} else {
				this.a_informacion_txvg=[]
			}
			const txvgd = this.a_detalle_texto.filter((parrafo) =>
				parrafo.parrafo != this.formParrafo.parrafo)
			if	(txvgd) {
				this.a_detalle_texto=txvgd
			} else {
				this.a_detalle_texto=[]
			}
			this.formParrafo.valor_campo0=null
			this.formParrafo.valor_campo1=null
			this.formParrafo.valor_campo2=null
			this.formParrafo.valor_campo3=null
			this.formParrafo.valor_campo4=null
			this.formParrafo.valor_campo5=null
			this.formParrafo.valor_campo6=null
			this.formParrafo.valor_campo7=null
			this.formParrafo.valor_campo8=null
			this.formParrafo.valor_campo9=null
			this.formParrafo.campo0 = null
			this.formParrafo.campo1 = null
			this.formParrafo.campo2 = null
			this.formParrafo.campo3 = null
			this.formParrafo.campo4 = null
			this.formParrafo.campo5 = null
			this.formParrafo.campo6 = null
			this.formParrafo.campo7 = null
			this.formParrafo.campo8 = null
			this.formParrafo.campo9 = null
		}
		if	(this.formAnterior.tipo == "TVC") {
			const txvc = this.a_informacion_txvc.filter((parrafo) =>
				parrafo.parrafo != this.formParrafo.parrafo)
			if	(txvc) {
				this.a_informacion_txvc = txvc
			} else	{
				this.a_informacion_txvc = []
			}
			const txvgd = this.a_detalle_texto.filter((parrafo) =>
				parrafo.parrafo != this.formParrafo.parrafo)
			if	(txvgd) {
				this.a_detalle_texto=txvgd
			} else {
				this.a_detalle_texto=[]
			}
			this.formParrafo.valor_campo0=null
			this.formParrafo.valor_campo1=null
			this.formParrafo.valor_campo2=null
			this.formParrafo.valor_campo3=null
			this.formParrafo.valor_campo4=null
			this.formParrafo.valor_campo5=null
			this.formParrafo.valor_campo6=null
			this.formParrafo.valor_campo7=null
			this.formParrafo.valor_campo8=null
			this.formParrafo.valor_campo9=null
			this.formParrafo.campo0 = null
			this.formParrafo.campo1 = null
			this.formParrafo.campo2 = null
			this.formParrafo.campo3 = null
			this.formParrafo.campo4 = null
			this.formParrafo.campo5 = null
			this.formParrafo.campo6 = null
			this.formParrafo.campo7 = null
			this.formParrafo.campo8 = null
			this.formParrafo.campo9 = null
		}
		if	(this.formAnterior.tipo == "TBM") {
			this.formParrafo.TBLM = []
		}
		if	(this.formParrafo.tipo == "TFG") {
			const r_txfg = {
				id:		0,
				numero:		this.formParrafo.numero,	
				parrafo:	this.formParrafo.parrafo,	
				vigencia:	this.formParrafo.vigencia,
				texto:		this.formParrafo.texto,	
				u_crea:		this.formParrafo.u_crea,	
				createdAt:	this.formParrafo.createdAt,	
				u_modifica:	2,
				uspdatedAt:	this.formParrafo.createdAt,
			}
			this.a_informacion_txfg.push(r_txfg)
		}
		if	(this.formParrafo.tipo == "TFC") {
			const r_txfc = {
				id:		0,
				numero:		this.formParrafo.numero,	
				parrafo:	this.formParrafo.parrafo,	
				vigencia:	this.formParrafo.vigencia,
				id_compania:	this.selCompany,
				texto:		this.formParrafo.texto,	
				u_crea:		this.formParrafo.u_crea,	
				createdAt:	this.formParrafo.createdAt,	
				u_modifica:	2,
				uspdatedAt:	this.formParrafo.createdAt,
			}
			this.a_informacion_txfc.push(r_txfc)
		}
		if	(this.formParrafo.tipo == "TVG" || this.formParrafo.tipo == "TVC") {
			const rd_txvc = {
				id:		0,
				numero:		this.formParrafo.numero,	
				parrafo:	this.formParrafo.parrafo,	
				vigencia:	this.formParrafo.vigencia,
				texto:		this.formParrafo.texto,
				campo0:		null,
				campo1:		null,
				campo2:		null,
				campo3:		null,
				campo4:		null,
				campo5:		null,
				campo6:		null,
				campo7:		null,
				campo8:		null,
				campo9:		null,
				u_crea:		this.formParrafo.u_crea,	
				createdAt:	this.formParrafo.createdAt,	
				u_modifica:	2,
				uspdatedAt:	this.formParrafo.createdAt,
			}
			this.a_detalle_texto.push(rd_txvc)
			if	(this.formParrafo.tipo == "TVG") {
				const ri_txvg = {
					id:		0,
					numero:		this.formParrafo.numero,	
					parrafo:	this.formParrafo.parrafo,	
					vigencia:	this.formParrafo.vigencia,
					valor_campo0:	null,
					valor_campo1:	null,
					valor_campo2:	null,
					valor_campo3:	null,
					valor_campo4:	null,
					valor_campo5:	null,
					valor_campo6:	null,
					valor_campo7:	null,
					valor_campo8:	null,
					valor_campo9:	null,
					u_crea:		this.formParrafo.u_crea,	
					createdAt:	this.formParrafo.createdAt,	
					u_modifica:	2,
					uspdatedAt:	this.formParrafo.createdAt,
				}
				this.a_informacion_txvg.push(ri_txvg)
			}
			if	(this.formParrafo.tipo == "TVC") {
				const ri_txvc = {
					id:		0,
					numero:		this.formParrafo.numero,	
					parrafo:	this.formParrafo.parrafo,	
					vigencia:	this.formParrafo.vigencia,
					id_compania:	this.selCompany,
					valor_campo0:	null,
					valor_campo1:	null,
					valor_campo2:	null,
					valor_campo3:	null,
					valor_campo4:	null,
					valor_campo5:	null,
					valor_campo6:	null,
					valor_campo7:	null,
					valor_campo8:	null,
					valor_campo9:	null,
					u_crea:		this.formParrafo.u_crea,	
					createdAt:	this.formParrafo.createdAt,	
					u_modifica:	2,
					uspdatedAt:	this.formParrafo.createdAt,
				}
				this.a_informacion_txvc.push(ri_txvc)
			}
		}
		if	(this.formParrafo.tipo == "TFG") {
			const txfg = this.a_informacion_txfg.find((parrafo) =>
				parrafo.parrafo == this.formParrafo.parrafo)
			if	(txfg) {
				this.formParrafo.texto = txfg.texto
			}
		}
		if	(this.formParrafo.tipo == "TFC") {
			const txfc = this.a_informacion_txfc.find((parrafo) =>
				parrafo.parrafo == this.formParrafo.parrafo)
			if	(txfc) {
				this.formParrafo.texto = txfc.texto
			}
		}
		if	(this.formParrafo.tipo == "TVG") {
			const txvg = this.a_informacion_txvg.find((parrafo) =>
				parrafo.parrafo == this.formParrafo.parrafo)
			if	(txvg) {
				let txvgd = this.a_detalle_texto.find((txvg) => 
					txvg.parrafo == this.formParrafo.parrafo
				)
				const dt = this.a_detalle_texto.find((variable) =>
						variable.parrafo == this.formParrafo.parrafo)
				this.formParrafo.texto = txvgd.texto
				this.formParrafo.valor_campo0 = dt.campo0
				this.formParrafo.valor_campo1 = dt.campo1
				this.formParrafo.valor_campo2 = dt.campo2
				this.formParrafo.valor_campo3 = dt.campo3
				this.formParrafo.valor_campo4 = dt.campo4
				this.formParrafo.valor_campo5 = dt.campo5
				this.formParrafo.valor_campo6 = dt.campo6
				this.formParrafo.valor_campo7 = dt.campo7
				this.formParrafo.valor_campo8 = dt.campo8
				this.formParrafo.valor_campo9 = dt.campo9
				this.formParrafo.campo0 = "Campo 0"
				this.formParrafo.campo1 = "Campo 1"
				this.formParrafo.campo2 = "Campo 2"
				this.formParrafo.campo3 = "Campo 3"
				this.formParrafo.campo4 = "Campo 4"
				this.formParrafo.campo5 = "Campo 5"
				this.formParrafo.campo6 = "Campo 6"
				this.formParrafo.campo7 = "Campo 7"
				this.formParrafo.campo8 = "Campo 8"
				this.formParrafo.campo9 = "Campo 9"
			}
		}
		if	(this.formParrafo.tipo == "TVC") {
			const txvc = this.a_informacion_txvc.find((parrafo) =>
				parrafo.parrafo == this.formParrafo.parrafo)
			if	(txvc) {
				let txvgd = this.a_detalle_texto.find((txvg) => 
					txvg.parrafo == this.formParrafo.parrafo
				)
				const dt = this.a_detalle_texto.find((variable) =>
						variable.parrafo == this.formParrafo.parrafo)
				this.formParrafo.texto = txvgd.texto
				this.formParrafo.valor_campo0 = dt.campo0
				this.formParrafo.valor_campo1 = dt.campo1
				this.formParrafo.valor_campo2 = dt.campo2
				this.formParrafo.valor_campo3 = dt.campo3
				this.formParrafo.valor_campo4 = dt.campo4
				this.formParrafo.valor_campo5 = dt.campo5
				this.formParrafo.valor_campo6 = dt.campo6
				this.formParrafo.valor_campo7 = dt.campo7
				this.formParrafo.valor_campo8 = dt.campo8
				this.formParrafo.valor_campo9 = dt.campo9
				this.formParrafo.campo0 = "Campo 0"
				this.formParrafo.campo1 = "Campo 1"
				this.formParrafo.campo2 = "Campo 2"
				this.formParrafo.campo3 = "Campo 3"
				this.formParrafo.campo4 = "Campo 4"
				this.formParrafo.campo5 = "Campo 5"
				this.formParrafo.campo6 = "Campo 6"
				this.formParrafo.campo7 = "Campo 7"
				this.formParrafo.campo8 = "Campo 8"
				this.formParrafo.campo9 = "Campo 9"
			}
		}
		if	(this.formParrafo.tipo == "TBM") {
			var av_filas = []
			var v_celda = ""
			var vd_celda = ""
			this.a_detalle_filas.forEach((fila) => {
				this.a_detalle_columnas.forEach((columna) => {
					vd_celda=fila.descripcion+'/'+columna.descripcion
					v_celda = this.a_informacion_tblm.find((celda) => 
						celda.fila == fila.fila && celda.columna == columna.columna)
					if	(v_celda) {
						av_filas.push({fila: fila.fila,columna: columna.columna,descripcion: vd_celda,valor: v_celda.valor})
					} else	{
						av_filas.push({fila: fila.fila,columna: columna.columna,descripcion: vd_celda,valor: 0})
					}
				})
			})
			this.formParrafo.TBLM = av_filas
		}
	},
	f_cambia_texto() {
		if	(this.formParrafo.tipo == "TFG") {
			const v_idx=this.a_informacion_txfg.findIndex((texto) => 
				texto.parrafo == this.formParrafo.parrafo
			)
			this.a_informacion_txfg[v_idx].texto = this.formParrafo.texto
		}
		if	(this.formParrafo.tipo == "TFC") {
			const v_idx=this.a_informacion_txfc.findIndex((texto) => 
				texto.parrafo == this.formParrafo.parrafo
			)
			this.a_informacion_txfc[v_idx].texto = this.formParrafo.texto
		}
		if	(this.formParrafo.tipo == "TVG"|| this.formParrafo.tipo == "TVC") {
			const v_idx=this.a_detalle_texto.findIndex((texto) => 
				texto.parrafo == this.formParrafo.parrafo
			)
			this.a_detalle_texto[v_idx].texto = this.formParrafo.texto
		}
	},
	f_cambia_campo(campo) {
		const dt = this.a_detalle_texto.findIndex((r_campo) =>
			r_campo.parrafo == this.formParrafo.parrafo)
		if	(campo==0) {
			this.a_detalle_texto[dt].campo0 = this.formParrafo.valor_campo0
		}
		if	(campo==1) {
			this.a_detalle_texto[dt].campo1 = this.formParrafo.valor_campo1
		}
		if	(campo==2) {
			this.a_detalle_texto[dt].campo2 = this.formParrafo.valor_campo2
		}
		if	(campo==3) {
			this.a_detalle_texto[dt].campo3 = this.formParrafo.valor_campo3
		}
		if	(campo==4) {
			this.a_detalle_texto[dt].campo4 = this.formParrafo.valor_campo4
		}
		if	(campo==5) {
			this.a_detalle_texto[dt].campo5 = this.formParrafo.valor_campo5
		}
		if	(campo==6) {
			this.a_detalle_texto[dt].campo6 = this.formParrafo.valor_campo6
		}
		if	(campo==7) {
			this.a_detalle_texto[dt].campo7 = this.formParrafo.valor_campo7
		}
		if	(campo==8) {
			this.a_detalle_texto[dt].campo8 = this.formParrafo.valor_campo8
		}
		if	(campo==9) {
			this.a_detalle_texto[dt].campo9 = this.formParrafo.valor_campo9
		}
	},
        grabeNota() {
		
		if	(this.formNueva.numero == '' || this.formNueva.descripcion == '') {
			this.$toast.error("Campo(s) pendiente(s) por diligenciar")
		} else	{
			let formData = {
				'numero' : this.formNueva.numero,
				'nota' : this.formNueva.descripcion,
				'idUser' : this.$store.state.userId
			}
			Notas.createNota(formData)
			.then((res) => {
				// console.log(res.data)
				this.$toast.success(res.data.message)
				this.refrescaTbl()
			})
			.catch((err) => {
				this.$toast.error(err.message)
			})
		}
	},
        actualizaNota() {
		
		if	(this.formNueva.descripcion == '') {
			this.$toast.error("Campo pendiente por diligenciar")
		} else	{
			let formData = {
				'id' : this.formNueva.id,
				'numero' : this.formNueva.numero,
				'nota' : this.formNueva.descripcion,
				'idUser' : this.$store.state.userId
			}
			Notas.updateNota(formData)
			.then((res) => {
				this.$toast.success(res.data.message)
				this.refrescaTbl()
			})
			.catch((err) => {
				this.$toast.error(err.message)
			})
		}
	},
	refrescaTbl() {
		Notas.getAllNotas()
		.then((res) => {
			this.listaNotas = res.data.listNotas
		})
		.catch((err) => {
			console.log(err)
		})
		this.showNuevo=false
		this.showEdita=false
	},
},
mounted(){
        PlanCuentas.getVigenciaPlancuentas()
        .then((res) => {
            this.listVigencia = res.data.getVigencia
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() => {
            this.isLoading = false;
        })

        Notas.getAllNotas()
        .then((res) => {
            // console.log(res.data)
            this.listaNotas = res.data.listNotas
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() => {
            this.isLoading = false;
        })
	Company.getAllCompanies()
	.then((result) => {
		this.listCompany = result.data.companies
		this.showSelCompany = true
	})
	.catch((err) => {
		console.log(err)
	})
        .finally(() => {
            this.isLoading = false;
        })
    },
}
</script>
