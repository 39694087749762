<template>
  <div class="container">
    <Edit :showDialogEdit="showDialogEdit" :Item="itemSelected" @closeModal="cerrarModal"/>
    <b-row>
      <b-container>
        <h2 class="text-center">
          {{labels.title}}
        </h2>
      </b-container>
    </b-row>
    <div class="row col-md-12">
      <div class="col-md-4">
        <md-field md-inline>
          <label>{{labels.name}}</label>
          <md-input v-model="searchRoles" @input="searchOnTableRoles"></md-input>
        </md-field>
      </div>
      <div class="col-md-8 d-flex justify-content-end">
        <td>
          <md-button style="background-color: #2e507a; width: 35px; height: 35px;" class="md-fab md-primary" @click="createRol()">
            <md-icon>add</md-icon>
          </md-button>
        </td>
      </div>
    </div>
    <b-container v-if="!isEmpty" class="pb-5">
        <table class="table table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">{{labels.name}}</th>
              <th scope="col">{{labels.description}}</th>
              <th scope="col">{{labels.associate}}</th>
              <th scope="col">{{labels.edit}}</th>
              <th scope="col">{{labels.inactivate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rol in datosPaginados" :key="rol.id">
              <td>{{ rol.nombre }}</td>
              <td>{{ rol.descripcion }}</td>
              <td>
                <md-button @click="asociaMenu(rol.id)" class = "md-raised md-primary btn-info"><md-icon>list</md-icon></md-button>
              </td>
              <td>
                <md-button @click="editRol(rol.id)" class = "md-raised md-primary btn-edit"><md-icon>edit</md-icon></md-button>
              </td>
              <td>
                <md-button @click="inactivarRol(rol.id)" class = "md-raised md-accent"><md-icon>delete</md-icon></md-button>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#">Previous</a></li>
            <li v-for="pagina in totalPaginas()" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
            <!-- <li v-for="pagina in pages.slice(paginaActual-1, paginaActual+9)" :key="pagina" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li> -->
            <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
    </b-container>
    <Create :showDialogCreate="showDialogCreate" @closeModal="cerrarModal" />
    <ModalAsociaMenu :modAsociaMenu="modAsociaMenu" :IdRol="rolId" @closeModal="cerrarModal"></ModalAsociaMenu>
  </div>
</template>

<script>
import Rol from "@/services/Roles";
import Edit from "./Edit.vue"
import Create from './Create.vue';
import ModalAsociaMenu from "./ModalAsociaMenu.vue";
const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchByRol = (items, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item.nombre).includes(toLower(term))
    );
  }
  return items;
};

export default {
    name: "Roles",
    components:{
      Edit,
      Create,
      ModalAsociaMenu
    },
    data: () => ({
        isLoading: false,
        error: false,
        isEmpty: false,
        searchRoles: null,
        roles:[],
        inscriptions: [],
        searched: [],
        elementosPorPagina:10,
        paginaActual: 1,
        datosPaginados: [],
        pages:[],
        showDialogEdit: false,
        itemSelected: {},
        listRol:[],
        showDialogCreate: false,
        modAsociaMenu: false,
        rolId: ""
    }),
    computed:{
        labels(){
            if (this.$store.state._language == "es-ES") {
                return {
                    title: "Roles",
                    instructive: ["Bienvenid@ a la plataforma para la visualización y administración de roles"],
                    create: "Crear",
                    name: "Nombre",
                    description: "Descripción",
                    associate: "Asociar menú",
                    edit: "Editar",
                    inactivate: "Inactivar",
                }
            }else {
                return {
                    title: "Roles",
                    instructive: ["Welcome to the platform for the visualization and manage of roles."],
                    create: "Create",
                    name: "Name",
                    description: "Description",
                    associate: "Associate menu",
                    edit: "Edit",
                    inactivate: "Inactivate",
                }
            }
        }
    },
    methods:{
      cerrarModal(val){
        this.showDialogEdit = val
        this.showDialogCreate = val
        this.modAsociaMenu = val
      },
      asociaMenu(idRol){
        // console.log(idRol)
        this.rolId = idRol
        this.modAsociaMenu = true
      },
        editRol(id){
            // console.log(id)
            // this.itemSelected = id;
            this.showDialogEdit = true;

            Rol.getAllRolesById(id)
            .then((rta) => {
              let datos = rta.data.rolById;
              // console.log(datos[0])
              this.itemSelected = datos[0];
            })
            .catch((error) => {
              console.log(error);
              this.error = true;
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
        createRol(){
          this.showDialogCreate = true

        },
        inactivarRol(id){
            this.isLoading = true;
            Rol.inactivarRol(id)
            .then(() => {
                // console.log(rta)
                this.$toast.success("Rol inactivado correctamente")
                this.isLoading = false;
            })
            .catch(() => {
                this.$toast.error("El rol no se pudo inactivar")
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
        searchOnTableRoles(){
            this.searchEmail = '';
            this.datosPaginados = searchByRol(this.roles, this.searchRoles);
            if(this.searchRoles == ''){
                this.getDataPagina(1)
            }
        },
        totalPaginas(){
            // let numberOfPages = Math.ceil(this.searched.length / this.elementosPorPagina);
            // for (let i = 1; i <= numberOfPages; i++) {
            //     this.pages.push(i);
            // }
            // return numberOfPages
            return Math.ceil(this.searched.length / this.elementosPorPagina)
        },
        showPaginas(){
            let inicio = this.paginaActual;
            let cantReg = 9;  
            // let fin = this.paginaActual - 5;
            let result =  inicio  + cantReg;
            return result;
        },
        getDataPagina(noPagina){
            this.datosPaginados = [];
            this.paginaActual = noPagina;
            let ini = (noPagina * this.elementosPorPagina) - this.elementosPorPagina;
            let fin = (noPagina * this.elementosPorPagina);

            this.datosPaginados = this.searched.slice(ini,fin);
        },
        getPreviousPage(){
            if(this.paginaActual > 1){
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage(){
            if(this.paginaActual < this.totalPaginas()){
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina){
            return noPagina == this.paginaActual ? 'active':'';
        }
    },
    mounted(){
        this.isLoading = true;
        Rol.getAllRoles()
        .then((rta) => {
            // console.log(rta.data.roles)
            this.roles = rta.data.roles;
            this.inscriptions = rta.data.roles;
            this.searched = this.roles;
            this.getDataPagina(1);
        })
        .catch(() => {
        // console.log(error);
        this.error = true;
        })
        .finally(() => {
        this.isLoading = false;
        });
    },
    watch:{
    inscriptions(){
      this.totalPaginas()
    }
  },
}
</script>
<style src="../main.css">
</style>